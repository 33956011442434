button {
  padding: 0;
  border: none;
  background: none;
}
button:focus {
  outline: 1px solid #eee;
}
.lexxbutton {
  width: 100%;
  border-radius: 0.5em;
}

.primary {
  background: #0971ce;
}
.secondaryButton {
  box-sizing: border-box;
  width: 250px;
  height: 40px;
  left: 695px;
  top: 187px;

  background: #ffffff !important;
  border: 1px solid #0971ce !important;
  border-radius: 4px;
}

.primaryButton {
  box-sizing: border-box;
  width: 250px;
  height: 40px;
  left: 942px;
  top: 187px;
  color: white !important;
  background: #034e91 !important;
  border: 1px solid #0971ce !important;
  border-radius: 4px;
}

.primary-pressed {
  background: #06559c;
}

.primary-disabled {
  background: #e0e0e0;
}

.secondary {
  border: 1px solid #0971ce;
  box-sizing: border-box;
}

.secondary-pressed {
  background: #0971ce;
  border: 1px solid #0971ce;
  box-sizing: border-box;
}

.secondary-disabled {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}

.lexxlabel {
  width: 100% !important;
  font-size: 1em !important;
  padding: 1em 0em !important;
  line-height: 1em !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
  text-align: center !important;
  margin: auto !important;
}

.label-image-class {
  background: none !important;
  height: 3.7em;
}

.primary-label {
  background: none !important;
  color: #ffffff !important;
}

.primary-pressed-label {
  background: none !important;
  color: #ffffff !important;
}

.primary-disabled-label {
  background: none !important;
  color: #bdbdbd !important;
}

.secondary-label {
  background: none !important;
  color: #0971ce !important;
}

.secondary-pressed-label {
  background: none !important;
  color: #ffffff !important;
}

.secondary-disabled-label {
  background: none !important;
  color: #bdbdbd !important;
}

.search-input {
  outline: 0;
  border-width: 0 0 0.1em;
  border-color: #0971ce;
  font-size: 1.5em;
  padding-top: 1em;
  margin-bottom: 1em;
  width: 65%;
  color: #333333;
  background: transparent;
  font-family: "Inter", sans-serif;
  line-height: 2em;
}

/* do not group these rules */
*::-webkit-input-placeholder {
  color: rgb(252, 252, 252);
}
*:-moz-placeholder {
  /* FF 4-18 */
  color: rgb(250, 250, 250);
  opacity: 1;
}
*::-moz-placeholder {
  /* FF 19+ */
  color: rgb(255, 255, 255);
  opacity: 1;
}
*:-ms-input-placeholder {
  /* IE 10+ */
  color: rgb(255, 255, 255);
}
*::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(255, 255, 255);
}
*::placeholder {
  /* modern browser */
  color: rgb(255, 255, 255);
}

.search-content {
  border-color: transparent;
  position: absolute;
  width: 369px;
  height: 35px;
  color: #ffffff;
  /* P500 */

  background: #003666;
  border-radius: 4px;
}

.sidemenu {
  position: absolute;
  width: 15% !important;
  height: 100%;
  margin-left: 0.15 !important;
  background: #ffffff;
  border: none !important;
  box-shadow: none !important;
  margin-top: 5% !important;
}
.sidebar-selected {
  background-color: #eef5fc !important;
  border-radius: 0px 40px 40px 0px !important;
  border-left: solid !important;
  border-color: #0971cd !important;
}
.bold-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}
i.arrow.right {
  font-size: 2em;
  color: #1a71ce;
  width: 5%;
  margin: 1.2em 0 0 0;
  float: right;
  position: absolute;
}

.card-key {
  width: 1.5em;
  height: 1.5em;
  background-color: #ffa500;
  color: #ffffff;
  border-radius: 50%;
  text-align: center;
}

.button-text {
  font-weight: 600;
}

.cardmeta-title {
  width: 50%;
  color: #bdbdbd;
}

.cardmeta-value {
  color: #000000;
  width: 50%;
}

.card-header {
  font-size: 1.2em;
  line-height: 1.4em;
  font-weight: 600;
  text-align: left;
  margin-bottom: 1em;
}

.card-description {
  margin-bottom: 1em;
  margin-top: 1em;
}

.lexx-card {
  background-color: #ffffff;
  border-radius: 5%;
  margin: 0.5em;
  margin-bottom: 1em;
}

.card-button {
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  margin-top: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;
  height: 3em;
  width: 100%;
  background: #f2f2f2;
  color: #0971ce;
  box-shadow: 0em 0.1em 0em #d1d2ce;
}

.card-content {
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  padding: 0% 5%;
  background: #ffffff;
  padding-top: 1em;
}
.app,
.app > div {
  height: 100%;
  background-color: #ffffff;
  /* display: grid; */
  padding-bottom: 4rem;
}
.app .pushable {
  transform: none;
}

/* .search-results-container{
    max-height: 75vh;
    width:100%;
    overflow-y: auto;
} */

/* .search-results-container::-webkit-scrollbar {
    display: none;
} */

.feedback-2 {
  width: 48%;
  float: left;
  margin-left: 1%;
  margin-right: 1%;
}

.feedback-1 {
  width: 23%;
  float: right;
  margin-left: 1%;
  margin-right: 1%;
}

.schematic-container {
  margin: 0 1.5em;
}

.schematic-title {
  padding-top: 2em;
  margin-bottom: 1em;
}

.schematic-header-table {
  margin-bottom: 2em;
}

.schematic-image-container {
  max-width: 100%;
  max-height: 95%;
}

.schematic-image {
  max-width: 100%;
  max-height: 100%;
}

.media-display {
  height: 100%;
  width: 100%;
  max-width: 100%;
  /* max-height: 100%; */
}

.tools {
  width: 100%;
  margin-bottom: 10px;
  height: 3em;
  display: inline-block;
}

.zoom-buttons {
  width: 50%;
  float: left;
  background: #f2f2f2;
}

.invert-color {
  filter: invert(1);
}

.zoom-buttons .invert-color {
  filter: opacity(0.6);
}

.search-results-page {
  margin-bottom: 2em;
}
.table-wrapper {
  max-width: none !important;
}
.dashboard-container {
  padding: 2rem;
  position: relative;
  z-index: 1;
  float: right;
  width: 100% !important;
  margin-top: 5% !important;
}

.dashboard-container .dashboard-card {
  margin: 1em 0 2em 0;
}

.home-container .container.dashboard-footer-container {
  position: fixed;
  bottom: 0%;
  left: 0%;
  height: 9vh;
  background-color: white;
  margin: 0 !important;
  z-index: 101;
  width: 100% !important;
}

.dashboard-card {
  width: 100% !important;
}

.card-inner-table {
  margin: 1em;
  align-items: center !important;
}

.dashboard-card-buttons {
  display: flex;
  justify-content: flex-end;
  padding-top: 1.5rem;
}

.linebreak {
  height: 0.5em;
}

.dashboard-card-content {
  padding: 1em 1em 0em 1em;
  width: 100%;
  height: 8em;
}

.dashboard-card-image {
  width: 10%;
  height: 100%;
  float: left;
}

.dashboard-card-image img {
  width: 64px;
  height: 64px;
  margin-top: 1em;
}

.dashboard-card-image-holder {
  width: 100%;
  max-width: 200px !important;
  height: 100%;
}

.profile-image-holder {
  width: 35%;
  height: 100%;
  cursor:pointer;
}

.dashboard-card-messages {
  padding: 1em 0em 0em 1em;
  width: auto;
  height: 6em;
  float: left;
}

.dashboard-card-messages .label {
  font-size: 15px;
  line-height: 22px;
  color: #333;
}

.dashboard-card-buttons button.lexxbutton.secondary,
.dashboard-card-buttons button.lexxbutton.secondary-disabled {
  margin-top: 0.5em;
}

td .message-content {
  text-align: left;
  font-weight: 800;
  font-size: 2em;
  font-family: "Inter", sans-serif;
  border: none;
}

.menu-height {
  height: 100%;
}

.footer-toolbar-image {
  height: 2em;
}

.footer-toolbar-text-selected {
  color: #0971ce;
  position: relative;
}

.left-float {
  float: left;
}

.right-float {
  float: right;
}

.blue-color {
  color: #0971ce;
}

.carousel-header {
  width: 100%;
  height: 1em;
  margin-bottom: 10px;
}

.sliding-cards {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}

.ui.card.carousel-card {
  margin: 0.5em 1em 1em 0.2em;
  display: inline-block;
  width: 164px;
  border-radius: 5%;
}
.carousel-header .section-header-text {
  font-size: 14px;
  margin: 0em;
}

.sliding-cards .ui.card .font10 {
  font-size: 10px;
}

.sliding-cards .ui.card .font12,
p.font12 {
  font-size: 12px;
}

.sliding-cards .ui.card .header.font14 {
  font-size: 14px;
}

.overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 144px;
  font-weight: 600;
  color: #1c2122;
}

.collaborators.ui.circular.label {
  width: 15px;
  height: 15px;
  padding: 0 !important;
}

.collaborators img {
  border-radius: 12px;
}

.sessions-wrapper div:nth-of-type(n + 6) {
  margin-left: -7px;
}

.sessions-wrapper,
.tasks-wrapper {
  margin-bottom: 1.5em;
}

.carousel-card .content a:nth-of-type(n + 1) {
  z-index: 65;
  position: relative;
  margin-left: -1em;
  padding: 0 !important;
  vertical-align: inherit;
}

.dashboard-footer-container .ui.menu .footer-menu.item {
  width: calc(100% / 6);
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 1em 0;
}
.dashboard-footer-container .ui.menu .footer-menu.item img {
  display: inline;
}
.dashboard-footer-container .ui.menu .footer-menu.item p {
  font-size: 8px;
  margin: 0.5em 0em 0em 0em;
  font-family: "Inter", sans-serif;
}
.work-order-grid.ui.grid {
  display: block;
  margin-bottom: 70px;
}
.schematics-container {
  margin: 2em 1em;
}
.card-key-visited {
  width: 1.5em;
  height: 1.5em;
  background-color: #27ae60;
  color: #ffffff;
  border-radius: 50%;
  text-align: center;
}
.container .procedure-wrapper.content-container-card {
  margin-bottom: 70px;
}
.login-container {
  width: 100vw;
  height: 100vh;
  padding: 100px 20px 20px 20px;
  background: linear-gradient(168.18deg, #2684d9 1.59%, #0b4c87 73.07%);
}

.login-container .ui.attached.segment {
  background: transparent;
  border: none;
}

.login-container .ui .row .column.login-section-container {
  min-width: 225px;
  max-width: 475px;
  display: flex;
  align-items: center;
}

.login-section-container .img {
  width: 156px;
  height: 56px;
}

.login-container .grid .row.login-heading {
  padding-top: 57px;
  padding-bottom: 0px;
}

.login-container .ui .row.login-heading .column {
  display: flex;
  align-items: center;
}

.login-container .ui .row.login-heading .column h3 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
}

.login-header {
  margin-top: 15px !important;
}

.login-section-container .login-signUp-wrapper .login-btn,
.login-section-container .login-signUp-wrapper .signup-btn {
  width: 100%;
  background: #fff;
  color: #0971ce;
  font-family: "Inter", sans-serif;
  font-style: normal;
}

.login-section-container .login-signUp-wrapper .signup-btn {
  margin-top: 10px;
}

.login-msg {
  color: red;
  margin: 5px 0px 15px 0px;
  background: #fff;
  width: 100%;
  text-align: center;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
}

.login-msg.noVisib {
  visibility: hidden;
}

.password-message {
  font-size: 10px;
  color: #979797;
}

.login-signUp-wrapper {
  text-align: center;
  margin-bottom: 10px;
}

.login-section-container a.label.forgetPassword,
.login-section-container a.label.forgetPassword:hover {
  background: none;
  text-align: center;
  width: 100%;
  color: #fff;
  font-family: "Inter", sans-serif;
}

.login-container.signup-container {
  padding-top: calc(50vh - 270px);
}

.login-container h4.ui.header {
  margin: 0.5em 0;
}

.login-container .login-field {
  margin-bottom: 15px;
  position: relative;
}

.login-field .ui.image {
  display: inline-block;
  position: absolute;
  top: 11px;
  left: 10px;
  z-index: 10;
}

.login-container .login-field input::placeholder {
  color: #fff !important;
}

.login-container .login-field input,
.login-container .login-field input:active,
.login-container .login-field input:focus {
  background: rgba(255, 255, 255, 0.2) !important;
  border: 1px solid #bdbdbd;
  height: 40px;
  color: #fff !important;
  padding-left: 35px;
}

.login-container .login-field .ui.icon.input > i.icon {
  color: white;
  opacity: 100;
}

.ui.container.feedback-btn {
  position: fixed;
  width: 95% !important;
  bottom: 2%;
}

.teamContainer {
  padding: 70px 25px 20px 25px;
}

.teamHeader {
  overflow: hidden;
}

img.ui.image.add-team-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
}

.team-update {
  color: #0971ce;
  border-right: 1px solid #e0e0e0;
  padding-right: 20px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: bottom;
  font-size: 14px;
  font-weight: bold;
  position: relative;
}

.team-update span {
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: #eb5757;
  border-radius: 20px;
  color: #fff;
  padding: 3px;
  position: absolute;
  top: -3px;
}

.searchTeam {
  width: 100%;
  padding: 20px 0;
}

.searchTeam .left.icon {
  width: 100%;
}

.searchTeam .ui.icon.input > input {
  border: 1px solid #bdbdbd;
  height: 40px;
}

.teamList .list .item {
  height: 48px;
  margin-bottom: 20px;
  position: relative;
}

.teamList .list .item img.avatar {
  width: 48px;
  height: 48px;
}

.teamList .list .item img.status {
  position: absolute;
  bottom: -2px;
  left: 30px;
}

.teamList {
  padding-top: 20px;
}

.teamList.content .list > .item > .image + .content {
  padding: 5px 0 0 15px;
  width: 60%;
}

.teamList .list .item .header {
  font-size: 16px;
  line-height: 20px;
}

.teamList .list .item .description {
  font-size: 12px;
  padding: 0 !important;
}

.teamList .list .item img.delete {
  width: 24px;
  height: 24px;
  margin-top: 10px;
}

.color-blue {
  color: #0971ce;
}

.cursor-pointer:hover {
  cursor: pointer;
}

i.icon.marT10 {
  margin-top: 14px;
}

.addMember-container.ui.modal > .header {
  padding: 20px !important;
}

.addMember-container .searchTeam {
  padding: 20px 15px;
}

.addMember-container {
  height: 100vh;
}

.user-profile-container {
  padding: 80px 0px;
  background: #f8f8f8;
}

.user-profile-container .user-profile-image {
  text-align: center;
  margin-bottom: 20px;
}

.user-profile-container .image-wrapper {
  position: relative;
  overflow: hidden;
}

.user-profile-container .image-wrapper .status-busy {
  position: absolute;
  bottom: -9px;
  right: 28%;
  width: 55px;
  height: 55px;
}

.user-profile-container .image-wrapper .status {
  position: absolute;
  bottom: 0px;
  right: 32%;
}

.user-profile-container .user-profile-image img.profile {
  width: 140px;
  height: 140px;
  border-radius: 70px;
}

.user-profile-container .user-profile-image img.delete-icon {
  float: right;
  padding-right: 10px;
  font-size: 1.2em;
  height: 1.2em;
}

.user-profile-container .user-profile-image img {
  display: inline-block;
}

.user-profile-container .user-profile-image h2 {
  margin: 20px 0 0 0;
}

.user-profile-container .user-profile-image h5 {
  margin: 0px;
  color: #828282;
  font-weight: 500;
}

.user-profile-about {
  margin: 0 15px;
  border-top: 1px solid #e0e0e0;
  padding: 15px 0px;
}

.user-profile-about label {
  font-size: 12px;
  color: #828282;
  padding-bottom: 5px;
  display: inline-block;
  font-weight: 600;
}

.user-profile-about p span {
  background: #e0e0d9;
  border-radius: 6px;
  font-size: 12px;
  color: #4f4f4f;
  padding: 3px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.user-profile-btn {
  background: #fff;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 72px;
}

.user-profile-btn button {
  margin: 16px 0px 16px 10px;
  width: 94%;
}

.notificationsContainer {
  padding: 0px 0px 20px 0px;
  background: #ffffff;
  margin-top: 4% !important;
}

.notificationsContainer img.ui.avatar.image {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.notificationsContainer .ui.list > .item {
  padding: 16px;
  border-bottom: 1px solid #f2f2f2;
}

.notificationsContainer .ui.list > .item.unread {
  background: #eef5fc;
}

.notificationsContainer .ui.list > .item > img.image + .content {
  float: right;
  width: calc(100% - 55px);
}

.notificationsContainer .item .content .header {
  padding-bottom: 10px;
  font-family: "Inter", sans-serif;
}

.notificationsContainer .content .header span {
  font-weight: 500;
}

.notificationsContainer .description span {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  color: #828282;
}

.lexxConfirmModal.ui.modal div.header {
  border: none;
}

.lexxConfirmModal.ui.modal div.header i {
  font-size: 1.2em;
}

.lexxConfirmModal.ui.modal div.content p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}

.lexxConfirmModal.ui.modal div.actions {
  border: none;
  background: none;
}

.lexxConfirmModal .grey-btn.ui.button,
.lexxConfirmModal .grey-btn.ui.button:hover,
.lexxConfirmModal .grey-btn.ui.button:focus {
  background: #f2f2f2;
  color: #828282;
  border: none;
}

.lexxConfirmModal .red-btn.ui.button,
.lexxConfirmModal .red-btn.ui.button:hover,
.lexxConfirmModal .red-btn.ui.button:focus {
  background: #eb5757;
  color: #ffffff;
  border: none;
}

.disable-color {
  color: #9c9c9c !important;
}

.disable-border {
  border: 1px solid #9c9c9c !important;
}

.disableicon{
 
  pointer-events: none;
}

/* Change the white to any color */
.login-container input:-webkit-autofill,
.login-container input:-webkit-autofill:hover,
.login-container input:-webkit-autofill:focus,
.login-container input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #3e75a6 inset !important;
}

/*Change text in autofill textbox*/
.login-container input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
}
@media only screen and (max-width: 767px) {
  .ui.table:not(.unstackable) tr {
    padding-top: 0;
  }
}
.image-fullscreen{
  width: 80%;
  height: 80%;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.image-close{
  font-size: x-large;
  position:absolute;
  top:0;
  right:0;
  font-weight: bolder;
  margin: 6px;
}

.modal-upload-image{
  width: fit-content !important;
  height: fit-content !important;
  text-align: center !important;
}

.images-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.step-desc table, th, td{
  border: 1px solid black;
border-collapse: collapse;
}